import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from "gatsby";
import { GridContainer, GridItem } from '@entur/grid';
import { colors, space } from '@entur/tokens';
import { StrongText, Paragraph } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import BaseCardDesignEntur from '~/components/BaseCardDesignEntur';
export const query = graphql`
query fotoQuery {
  files: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "images/foto"}, extension: {eq: "jpg"}}) {
    images: nodes {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "bilde1").childImageSharp.fluid} alt="Buss som kjører over vinterlandskap" preset="full-width-image" mdxType="ImageDisplay" />
    <h2 {...{
      "id": "tilgang-til-fotobank"
    }}>{`Tilgang til fotobank`}</h2>
    <p>{`Noen av bildene har lisens og krever derfor tilgang. Alle bilder er tilgjengelig bak innlogging via `}<a parentName="p" {...{
        "href": "https://drive.google.com/drive/folders/1MSzIoGAtemTZ3IR9Xsj8_uI_Ze3rZL6B?usp=drive_link"
      }}>{`Google Drive`}</a>{`.`}</p>
    <h2 {...{
      "id": "eksempler-på-motiver"
    }}>{`Eksempler på motiver`}</h2>
    <h3 {...{
      "id": "tilbyderne-og-transportmidlene"
    }}>{`Tilbyderne og transportmidlene`}</h3>
    <p>{`For eksempel: infrastrukturen – stasjoner og holdeplasser – detaljer fra transportmidlene – nettverket – trafikk`}</p>
    <GridContainer spacing="large" style={{
      marginBottom: space.extraLarge7 * -1
    }} mdxType="GridContainer">
  <GridItem small={6} medium={4} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "transport1").childImageSharp.fluid} alt="Person med ryggsekk på perongen ved siden av et tog" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "transport2").childImageSharp.fluid} alt="Tog som  kjører i vinterlandskap" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "transport3").childImageSharp.fluid} alt="Veikryss som viser til trafikk og bevegelser" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
    </GridContainer>
    <h3 {...{
      "id": "teknologien-møter-menneskene"
    }}>{`Teknologien møter menneskene`}</h3>
    <p>{`For eksempel: reiseplanleggeren – de ansatte – kundesenter – teknologi i bruk på jobb – digitale kanaler i bruk`}</p>
    <GridContainer spacing="large" style={{
      marginBottom: space.extraLarge7 * -1
    }} mdxType="GridContainer">
  <GridItem small={6} medium={4} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "teknologi1").childImageSharp.fluid} alt="Person med ryggsekk på perongen ved siden av et tog" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "teknologi2").childImageSharp.fluid} alt="Tog som  kjører i vinterlandskap" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "teknologi3").childImageSharp.fluid} alt="Veikryss som viser til trafikk og bevegelser" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
    </GridContainer>
    <h3 {...{
      "id": "destinasjonene-og-de-reisende"
    }}>{`Destinasjonene og de reisende`}</h3>
    <p>{`For eksempel: fjord og fjell – by og bygd – de reisende i møte med det kollektive – familier og grupper – pendlere`}</p>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={6} medium={4} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "reisende1").childImageSharp.fluid} alt="Person med ryggsekk på perongen ved siden av et tog" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "reisende2").childImageSharp.fluid} alt="Tog som  kjører i vinterlandskap" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "reisende3").childImageSharp.fluid} alt="Veikryss som viser til trafikk og bevegelser" preset="full-width-image" mdxType="ImageDisplay" />
  </GridItem>
    </GridContainer>
    <h2 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h2>
    <h3 {...{
      "id": "sammen-er-vi-bedre"
    }}>{`Sammen er vi bedre`}</h3>
    <p>{`Vi synliggjør mulighetene og potensialet i et samlet kollektiv-Norge, og verdien det har for de reisende.
Vi løfter frem mangfoldigheten i infrastrukturen og landet vårt, og alle de ulike turene det inviterer til.
Vi spiller på kontraster; fra nord til sør, det store toget og den lille lokale fergen.`}</p>
    <h3 {...{
      "id": "den-røde-tråden"
    }}>{`Den røde tråden`}</h3>
    <p>{`Vi bygger identitet i bildene, ofte med motiver med tydelige horisontale linjer.
Linjene går til kant; de starter og stopper utenfor rammen.
Det gir bildene et dynamisk uttrykk og kobler dem til en større sammenheng.
Det grafiske virkemiddelet skaper et rent uttrykk, strammer og skaper ro i komposisjonen.
Det gir også muligheten til å binde flere foto sammen med en, uavbrutt linje eller horisont.
Det er ikke i alle foto at dette grepet er mulig. Vi forsøker i det lengste å binde disse fotoene sammen med den fine, røde tråden.
Reisen som går uavbrutt gjennom motivene.`}</p>
    <h3 {...{
      "id": "lett-og-leken"
    }}>{`Lett og leken`}</h3>
    <p>{`Bildene uttrykker en følelse av mestring hos brukerne, og viser en sømløs integrering av teknologi i hverdagen.
Bildene uttrykker liv og bevegelse og har en positiv stemning.
Vi viser mennesker i bevegelse, og teknologien i bruk.`}</p>
    <h2 {...{
      "id": "stil-og-tone"
    }}>{`Stil og tone`}</h2>
    <BaseCardDesignEntur mdxType="BaseCardDesignEntur">
  <StrongText mdxType="StrongText">Naturlig lys</StrongText>
  <Paragraph margin="none" mdxType="Paragraph">
    Bildene har et lett, nordisk uttrykk med mye blåstikk. Foto tas i naturlig
    lys. Ta gjerne bilder utendørs.
  </Paragraph>
    </BaseCardDesignEntur>
    <BaseCardDesignEntur mdxType="BaseCardDesignEntur">
  <StrongText mdxType="StrongText">Ekte farger</StrongText>
  <Paragraph margin="none" mdxType="Paragraph">
    Fargene er naturlige og avspeiler virkeligheten. God kontrast underbygger
    det grafiske uttrykket.
  </Paragraph>
    </BaseCardDesignEntur>
    <BaseCardDesignEntur mdxType="BaseCardDesignEntur">
  <StrongText mdxType="StrongText">I bevegelse</StrongText>
  <Paragraph margin="none" mdxType="Paragraph">
    Vi skaper et livlig uttrykk ved å fokusere på personer i bevegelse eller
    transportmidler og teknologi i bruk.
  </Paragraph>
    </BaseCardDesignEntur>
    <BaseCardDesignEntur mdxType="BaseCardDesignEntur">
  <StrongText mdxType="StrongText">Rett på</StrongText>
  <Paragraph margin="none" mdxType="Paragraph">
    Vi forsøker å være rett på der det er mulig. Ved å ta bildene rett på
    skaper vi et rent, grafisk og ærlig uttrykk.
  </Paragraph>
    </BaseCardDesignEntur>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      